import React, { ReactElement, useMemo } from 'react'
import {
  alpha,
  Grid,
  MenuItem,
  NativeSelect,
  NativeSelectProps,
  Select,
  SelectProps,
  useMediaQuery,
} from '@material-ui/core'
import { DomParser } from './domParser'
import { isTag } from 'domhandler/lib/node'
import { Button } from '../../components/button/button'
import styled, { useTheme } from 'styled-components'
import { SelectStyles } from '../../components/form/Select'
import { Icon } from '../../components/icon/icon'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'

const freeformRow: DomParser = (element, props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Grid container spacing={isMobile ? 0 : 6} { ...props } component={'div'} />
  )
}

freeformRow.match = (element, props, utils) => utils.classes().includes('freeform-row')

const freeformColumn: DomParser = (element, props) => <Grid xs={12} sm item { ...props } component={'div'} />

freeformColumn.match = (element, props, utils) => utils.classes().includes('freeform-column')

const radio: DomParser = (element, { children, ...props }) => {
  const type = props.type

  const classes = ['fake-radio', `fake-radio--${type}`]

  return (
    <>
      <input { ...props } />
      <span className={classes.join(' ')}>
        {type === 'checkbox' ? (
          <Icon icon={faCheck} />
        ) : null}
      </span>
    </>
  )
}

radio.match = (element, props) => element.name === 'input' && (props.type === 'radio' || props.type === 'checkbox')

const radioLabel: DomParser = (element, props) => {
  return (
    <label tabIndex={0} onKeyPress={e => { // eslint-disable-line
      if (e.code === 'Enter') {
        const input = e.currentTarget.querySelector('input')

        if (input) {
          input.checked = true
        }
      }
    }} className={'freeform-radio-label'} { ...props } />
  )
}

radioLabel.match = (element, props) => {
  if (element.name === 'label' && Object.keys(props).length === 1 && element.children.length > 0) {
    const firstChild = element.children[0]

    return Boolean(firstChild && isTag(firstChild) && (firstChild.attribs.type === 'radio' || firstChild.attribs.type === 'checkbox'))
  }

  return false
}

const submitButton: DomParser = (element, props) => <Button cta { ...props } />

submitButton.match = (element, props) => element.name === 'button' && props.type === 'submit'

const StyledSelect = styled(Select)`
  ${SelectStyles};

  padding-left: ${props => props.theme.typography.pxToRem(6)};
`

const StyledNativeSelect = styled(NativeSelect)`
  height: ${props => props.theme.typography.pxToRem(50)};
  font-size: ${props => props.theme.typography.pxToRem(16)};
  border: ${props => props.theme.typography.pxToRem(1)} ${props => alpha(props.theme.palette.text.secondary, 0.19)} solid;
  border-radius: 4px;

  .MuiNativeSelect-select {
    padding-left: ${props => props.theme.typography.pxToRem(20)};
  }

  &::before,
  &::after {
    display: none;
  }
`

type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
}

type SimpleSelectProps = Omit<Common<SelectProps, NativeSelectProps>, 'onChange' | 'variant' | 'defaultValue' | 'inputProps'> & { multiple?: boolean }

const SimpleSelect: React.FC<SimpleSelectProps> = ({ children, ...props }) => {
  const isMouse = useMediaQuery('(hover:hover)')
  const options = React.Children.toArray(children).filter(child => typeof child === 'object' && 'props' in child) as ReactElement[]
  const defaultValue = useMemo(() => options.find(opt => opt.props.selected)?.props.value ?? undefined, [options])

  return !isMouse ? (
    <StyledNativeSelect defaultValue={props.multiple ? [defaultValue] : defaultValue} variant={'outlined'} { ...props }>
      {options}
    </StyledNativeSelect>
  ) : (
    <StyledSelect defaultValue={props.multiple ? [defaultValue] : defaultValue} variant={'outlined'} { ...props }>
      {options.map(({ props }, i) => (
        <MenuItem key={i} { ...props } />
      ))}
    </StyledSelect>
  )
}

const select: DomParser = (element, props) => <SimpleSelect { ...props as SimpleSelectProps } />

export const forms = { freeformRow, freeformColumn, radio, radioLabel, submitButton, select }