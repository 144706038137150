import React, { useCallback, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Alert, AlertProps } from '@material-ui/lab'
import hash from 'object-hash'

export const StyledAlert = styled(Alert)<{ $type?: AlertNoticeProps['type']; display: boolean }>`
  display: ${props => props.display ? 'flex' : 'none'};
  border-radius: unset;

  .MuiAlert-icon {
    color: #ed8b00;
  }

  .MuiAlert-root {
    padding: ${props => props.theme.typography.pxToRem(24)} ${props => props.theme.typography.pxToRem(29)} ${props => props.theme.typography.pxToRem(10)} ${props => props.theme.typography.pxToRem(32)};
    ${props => props.theme.breakpoints.up('sm')} {
      padding: ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(57)} ${props => props.theme.typography.pxToRem(35)} ${props => props.theme.typography.pxToRem(58)};
    }
  }

  .MuiAlert-message {
    font-size: ${props => props.theme.typography.pxToRem(14)};

    ${props => props.theme.breakpoints.up('sm')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }

    ${props => props.theme.breakpoints.up('md')} {
      font-size: ${props => props.theme.typography.pxToRem(17)};
    }
  }

  a {
    color: ${props => props.theme.palette.secondary.dark};
  }

  ${props => props.$type === 'primary' && css`
    color: #1c1f2a;
    background-color: #f2f2f2;
  `}

  ${props => props.$type === 'light' && css`
    color: #1c1f2a;
    background-color: #fff7e6;
  `}

  ${props => props.$type === 'dark' && css`
    color: white;
    background-color: ${(props) => props.theme.palette.text.primary};
  `}
`

export type AlertNoticeProps = AlertProps & {
  type?: 'light' | 'dark' | 'primary'
  hashInfo: string
};

export const GlobalAlert: React.FC<AlertNoticeProps> = ({ children, type, hashInfo, ...props }) => {
  const [displayState, setDisplay] = useState(false)

  useEffect(() => {
    const hashedChild = hash(hashInfo)

    if (localStorage.getItem(hashedChild) !== 'hidden') {
      setDisplay(true)
    }
  }, [hashInfo])

  const setDisplayStorage = useCallback((unhashedChild) => {
    localStorage.setItem(hash(unhashedChild), 'hidden')
    setDisplay(false)
  }, [])

  return (
    <StyledAlert display={displayState} onClose={() => {setDisplayStorage(hashInfo)}} severity="info" $type={type} { ...props }>{children}</StyledAlert>
  )
}
