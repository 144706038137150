import React from 'react'
import { isNotNull } from '../../../utils/nonNull'
import { html } from '../../../utils/htmlParser'
import { createPortal } from 'react-dom'
import { GlobalAlert } from '../../GlobalAlert/globalAlert'
import type { AlertGlobals } from '../../../globals/context'

export type AlertProps = AlertGlobals

export const AlertsFragment: React.FC<AlertProps> = ({ globalSet }) => {
  if (!globalSet || globalSet.__typename !== 'alertsTab_GlobalSet' || globalSet?.globalAlerts?.[0]?.__typename !== 'globalAlerts_BlockType') {
    return null
  }

  const { cookieAlert, alerts } = globalSet?.globalAlerts?.[0]

  const portal = typeof document !== 'undefined' ? document.querySelector('.alert-container') : null

  const alertContainers = (
    <>
      {typeof window !== 'undefined' &&
    <>
      {cookieAlert &&
      <GlobalAlert hashInfo={cookieAlert} type={'dark'}>
        {html(cookieAlert)}
      </GlobalAlert>
      }
      {isNotNull(alerts) &&
      alerts.map((item) => (item?.enableAlert && item?.alertContent &&
        <GlobalAlert hashInfo={item.alertContent} type={item?.alertStyle === 'primary' ? 'primary' : (item?.alertStyle === 'dark' ? 'dark' : 'light')}>
          {html(item.alertContent)}
        </GlobalAlert>
      ))
      }
    </>
      }
    </>
  )

  return portal ? createPortal(alertContainers, portal) : alertContainers
}
